import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { servicesLinks } from 'components/Layout/navigation';
import Service from 'components/Service_Us_Customes_Clearance';
import { ImgGatsbyBackground } from 'components/wrappers';
import Icon1 from 'images/cheapest-price-sourcing.png';
import Icon2 from 'images/bilingual-sourcing.png';
import Icon3 from 'images/product-improvement-sourcing.png';
import Step1 from 'images/requirements-img.png';
import Step2 from 'images/communication-img.png';
import Step3 from 'images/production-img.png';
import Step4 from 'images/Shipment-img.png';
import Step5 from 'images/order-finished-img.png';
const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query UsCustomsClearancePage {
      bg: file(relativePath: { eq: "us-customes-clearance.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.bg.childImageSharp.fluid} alt="Leave the sourcing part to us so you can focus on your business development" />;
  const IconCardOne = () => <img src={Icon1} className="service-icons" alt="service icons" />;
  const IconCardTwo = () => <img src={Icon2} className="service-icons" alt="service icons" />;
  const IconCardThree = () => <img src={Icon3} className="service-icons" alt="service icons" />;
  const intl = useIntl()
  return (
    <Service
      BackgroundImage={BackgroundImage}
      IconCardOne={IconCardOne}
      IconCardTwo={IconCardTwo}
      IconCardThree={IconCardThree}
      navigationTabs={servicesLinks}
      activeTab={servicesLinks[4].value}
      description={
        <>
          {intl.formatMessage({ id: "us-customes-clearance-header-description" })}
        </>
      }
       description2={
         <>
           {intl.formatMessage({ id: "us-customes-clearance-header-description2" })}
         </>
       }
      header={
        <>
          {intl.formatMessage({ id: "us-customes-clearance-sub-header-description" })}
        </>
      }
      about={intl.formatMessage({ id: "us-customes-clearance-header" })}
      cardsTitle={[
        'Platform Integrations',
        'Global Fulfillment',
        'Express Shipping',
      ]}
      cards={[
        'A robust fulfillment system, in which a one-click order syncs with 100+ marketplaces and platforms',
        'Fulfillment centers and bonded facilities in China, the UK, the US, Japan, Korea, Poland and Vietnam  means that you’re always close to a Shipkoo hub',
        'On-demand service at fast speeds: Shipkoo sends out deliveries within 24 hours of the order being placed',
      ]}
      //numSteps={intl.formatMessage({ id: "us-customes-clearance-steps-title" })}
      timelineItems={[
        {
          titleStep: 'Step 1',
          title: 'Requirement',
          description:'You provide product pictures and similar product links, we source for products and manufacturer at the lowest price while maintaining the finest product quality.',
          image: Step1,
          alt: 'shipkoo ecommerce order shipping fulfillment service',
          titleStepZH: '第一步',
          titleZH: '要求',
          descriptionZH: '您提供产品图片和类似产品链接，我们以最低的价格为产品和制造商采购，同时保持最好的产品质量。',
          titleStepKO: '1단계',
          titleKO: '필요',
          descriptionKO: '귀사는 제품 사진 및 이와 유사한 제품 링크를 제공하며, 최상의 제품 품질을 유지하면서 제품 및 제조업체를 최저 가격으로 공급합니다.',
          titleStepJP: 'ステップ1',
          titleJP: '要件',
          descriptionJP: 'あなたは製品写真と同様の製品リンクを提供します、私たちは最高の製品品質を維持しながら最低価格で製品とメーカーを調達します。',
        },
        {
          titleStep: 'Step 2',
          title: 'Communication',
          description:'We act as your local representative, conducting physical meetings with local manufacturers and building solid relationships to negotiate for the best terms and prices.',
          image: Step2,
          alt: 'shipkoo best ecommerce fulfillment service thorough product inspection and stocking',
          titleStepZH: '第二步',
          titleZH: '沟通',
          descriptionZH: '我们作为您的当地代表，与当地制造商进行实体会议并建立稳固的关系，以协商最佳条款和价格。',
          titleStepKO: '2단계',
          titleKO: '의사소통, 연락',
          descriptionKO: 'NAT은 귀사의 지역 담당자로서 현지 제조업체와 물리적 미팅을 진행하고, 최상의 조건과 가격을 협상하기 위해 견고한 관계를 구축합니다.',
          titleStepJP: 'ステップ2',
          titleJP: 'コミュニケーション',
          descriptionJP: '私たちはあなたの地元の代表として行動し、地元のメーカーとの物理的な会議を行い、最良の条件と価格について交渉するための強固な関係を構築します。',
        },
        {
          titleStep: 'Step 3',
          title: 'Production',
          description: 'We follow up on the production process and quality checking, and help negotiate with the manufacturer on inventory terms. ',
          image: Step3,
          alt: 'shipkoo best ecommerce origin fulfillment service',
          titleStepZH: '第三步',
          titleZH: '生产',
          descriptionZH: '我们跟进生产过程和质量检查，并帮助与制造商就库存条款进行谈判。',
          titleStepKO: '3단계',
          titleKO: '생산.',
          descriptionKO: '우리는 생산 공정과 품질 검사를 추적하고, 재고 조건에 대해 제조사와 협상하는 것을 돕는다.',
          titleStepJP: 'ステップ3',
          titleJP: '製造',
          descriptionJP:'製造工程と品質チェックをフォローアップし、在庫条件についてメーカーとの交渉を支援します。',
        },
        {
          titleStep: 'Step 4',
          title: 'Shipment',
          description: 'After the production is completed, we plan and arrange for the shipments: we pick up the finished products and ship them to the designated country; we handle all customs and taxes; we deliver to your doorstep.',
          image: Step4,
          alt: 'shippcoo ecommerce order fulfillment express shipping service',
          titleStepZH: '第四步',
          titleZH: '运输',
          descriptionZH: '生产完成后，我们计划和安排发货：我们将成品提取并运送到指定国家； 我们处理所有海关和税收； 我们送货上门。',
          titleStepKO: '4단계',
          titleKO: '수송',
          descriptionKO: '생산이 완료된 후, 우리는 배송을 계획하고 준비합니다. 우리는 완제품을 픽업하여 지정된 국가로 배송합니다. 우리는 모든 관세와 세금을 처리합니다. 우리는 당신의 현관까지 배달합니다.',
          titleStepJP: 'ステップ4',
          titleJP: '発送',
          descriptionJP: '生産が完了した後、出荷の計画と手配を行います。完成品を受け取り、指定された国に出荷します。 私たちはすべての税関と税金を処理します。 私たちはあなたの玄関先に配達します。',
        },
        {
          titleStep: 'Step 5',
          title: 'Order finished',
          description: 'We help with the communication between you and your manufacturer on the product improvements and feedbacks.',
          image: Step5,
          alt: 'shipkoo ecommerce order fulfillment service product pickup',
          titleStepZH: '第五步',
          titleZH: '订单完成',
          descriptionZH: '我们帮助您和您的制造商就产品改进和反馈进行沟通。',
          titleStepKO: '5단계',
          titleKO: '주문완료',
          descriptionKO: '제품 개선 및 피드백에 대한 귀사와 귀사의 제조업체 간의 커뮤니케이션을 지원합니다.',
          titleStepJP: 'ステップ5',
          titleJP: '注文が終了しました',
          descriptionJP: '製品の改善とフィードバックに関するお客様とメーカー間のコミュニケーションを支援します。',
        },
      ]}
    />
  );
};

export default ServicesPage;
